<template>
  <div class="content-wrapper bg-white">
    <header class="pt-3 d-flex align-items-center header ml-2">
      <font-awesome-icon
        icon="fa-solid fa-gear"
        class="mb-2 font-color-custom nav-icon text-muted"
      />
      <h3 class="text-muted ml-2">Configurações Gerais</h3>
    </header>
    <main class="mt-3 d-flex flex-column">
      <a class="btn btn-sm btn-primary align-self-end mr-3" @click.prevent="updateSettings">Salvar</a>
      <form class="ml-4">
        <div class="d-flex align-items-center">
          <div class="d-flex col-12 ">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="account_limit_days"
                >Quantidade de mensagens - Regra de engajados</label
              >
            </div>
            <div class="form-group col-lg-6">
              <div class="d-flex align-items-center">
                <span
                  class="d-flex justify-content-center align-items-center btn-group ml-2 mr-2"
                >
                  <button
                    style="font-weight: 700"
                    class="btn btn-secondary"
                    @click.prevent="--qtdMessages"
                    :disabled="qtdMessages <= 0"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    class="input-max-included-users form-control pl-4"
                    v-model.number="qtdMessages"
                  />
                  <button
                    style="font-weight: 700"
                    class="btn btn-secondary"
                    @click.prevent="++qtdMessages"
                  >
                    +
                  </button>
                </span>
                <button class="info" data-toggle="tooltip" data-placement="right" title="O valor informado, se torna o limite para validar o engajamento dentro do período de 3 dias considerando os horários de funcionamento da empresa. Limite <= qtdDeMensagensEnviadas = Engajado"><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form class="ml-4">
        <div class="d-flex align-items-center">
          <div class="d-flex col-12 ">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="account_limit_days"
                >pagamentos prazo líquido dias</label
              >
            </div>
            <div class="form-group col-lg-6">
              <div class="d-flex align-items-center">
                <span
                  class="d-flex justify-content-center align-items-center btn-group ml-2 mr-2"
                >
                  <button
                    style="font-weight: 700"
                    class="btn btn-secondary"
                    @click.prevent="--qtdDays"
                    :disabled="qtdDays === 0"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    class="input-max-included-users form-control pl-4"
                    v-model.number="qtdDays"
                  />
                  <button
                    style="font-weight: 700"
                    class="btn btn-secondary"
                    @click.prevent="++qtdDays"
                  >
                    +
                  </button>
                </span>
                <button class="info" data-toggle="tooltip" data-placement="right" title="num sei o que e para colocar aqui"><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qtdMessages: 0,
      qtdDays: 0,
    };
  },
  created() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
  mounted() {
    this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
      `Bearer ${this.$store.getters.tokenJWT}`;
    this.getSettings()
  },
  methods: {
    async getSettings() {
			const response = await this.$tallos_admin_api
				.get(`settings`)
      this.qtdMessages = response.data.qtdMessagesLimitEngaged
      this.qtdDays = response.data.payments_net_term_days
    },
    async updateSettings() {
      await this.$tallos_admin_api
        .put(`settings`, {
          payments_net_term_days: this.qtdDays,
          qtdMessagesLimitEngaged: this.qtdMessages,
        })
      this.$toasted.global.defaultSuccess({msg: 'Configurações atualizadas com sucesso'})
    }
  }
};
</script>

<style scoped>
.header {
  border-bottom: 1px solid #6a737c1f;
}

.font-color-custom,
.font-color-custom:hover {
  font-weight: 600 !important;
  font-size: 30px;
}

.input-max-included-users {
  height: 37px;
  border-radius: 0px !important;
  min-width: 80px;
  width: 100px;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

.word-custom {
  color: #666666;
  font-size: 15.4px;
}

.info {
  all: unset;
}
</style>
